import React from 'react';
import { NavLink } from 'react-router-dom';
import { isInternalURL, toBackendLang } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { getBaseUrl, hasApiExpander, flattenToAppURL, get } from '@plone/volto/helpers';

const NavItem = (props) => {
  const { settings } = config;
  const { item, lang, pathname } = props;
  // The item.url in the root is ''
  // TODO: make more consistent it everywhere (eg. reducers to return '/' instead of '')

  /*
  if (project === true) {
    return (
      <div className="item" onClick={click}>
        {item.title}
      </div>
    )
  }
  */

  if (isInternalURL(item.url) || item.url === '') {
    return (
      <NavLink
        to={item.url === '' ? '/' : item.url}
        key={item.url}
        className="item"
        activeClassName="active"
        exact={true} // Check for exact match
        // wenn der tatsächliche pfad die nav enthält -> acttive
      >
        {item.title}
      </NavLink>
    );
  } else {
    return (
      <a
        href={item.url}
        key={item.url}
        className="item"
        rel="noopener noreferrer"
        
        target={settings.openExternalLinkInNewTab ? '_blank' : '_self'}
      >
        {item.title}
      </a>
    );
  }
};

export default NavItem;
