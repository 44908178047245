import React, { useEffect, useState, useCallback } from 'react';
import { RenderBlocks } from "@plone/volto/components"
import { Container, Image } from 'semantic-ui-react'
import ProjektHeader from './ProjektHeader';
import { UniversalLink } from '@plone/volto/components';
import { Api, flattenToAppURL } from '@plone/volto/helpers';
import { CSSTransition } from "react-transition-group";

const ProjektPageView = (props) => {
    const { content, history, location, token } = props;
    const [parentData, setParentData] = useState(content);
    const parentURL = content.parent['@id']
    const pathname = content['@id'];
    const [loaded, setLoaded] = useState(false);
    const [imageSrc, setImageSrc] = useState('/bilder/dummy-bg.png/@@images/image');

    const testParents = async (pathname) => {
        const api = new Api();
        const promise = await api.get(flattenToAppURL(pathname));
        const data = promise
        return data;
    };

    const apiGet = async (pathname) => {
        const parentdata = testParents(parentURL)
        return parentdata;
    };

    useEffect(() => {
        if (!loaded)
            apiGet(pathname).then((fetchedItems) => {
                setImageSrc(fetchedItems.hintergrundbild.download)
                setParentData(fetchedItems);
                setLoaded(true);
                return;
            });

        // avoid Memory Leak
        return () => {
            setImageSrc('/bilder/dummy-bg.png/@@images/image');
            setParentData(content);
            setLoaded(false);
        };    
    }, [pathname]);

    //if content.hintergrund_ausschalten is true, dont render a container
    return (
        <>
            <ProjektHeader pathname={flattenToAppURL(content['@id'])} logo={parentData.projektlogo ? parentData.projektlogo.download : ""} parent={parentURL} farbe={parentData.farbe} />
            <Image
                alt={"Projekt Logo"}
                title={"Projekt Logo"}
                src={flattenToAppURL(imageSrc)}
                className={`projekt-head-img ${loaded ? 'fade-in' : ''}`}
            />

            <Container>
                <div className="ui grid column-grid projekt">
                    <div className="nine wide computer twelve wide mobile six wide tablet column column-blocks-wrapper ">
                        <Container className={content.hintergrund_ausschalten ? "transparent" : ""}>
                            <RenderBlocks {...props} content={content || {}} />
                        </Container>
                    </div>
                    <div className="three wide computer twelve wide mobile six wide tablet column column-blocks-wrapper">
                        {(parentData.sponsor_1 || parentData.sponsor_2 || parentData.sponsor_3 || parentData.sponsor_4) && (
                            <Container className="projekt-right-column">
                                <p>gefördert durch:</p>
                                {parentData.sponsor_1 && <Image
                                    alt={"Sponsor 1"}
                                    title={"Sponsor 1"}
                                    src={flattenToAppURL(parentData.sponsor_1.download)}
                                />}
                                {parentData.sponsor_2 && <Image
                                    alt={"Sponsor 2"}
                                    title={"Sponsor 2"}
                                    src={flattenToAppURL(parentData.sponsor_2.download)}
                                />}
                                {parentData.sponsor_3 && <Image
                                    alt={"Sponsor 3"}
                                    title={"Sponsor 3"}
                                    src={flattenToAppURL(parentData.sponsor_3.download)}
                                />}
                                {parentData.sponsor_4 && <Image
                                    alt={"Sponsor 4"}
                                    title={"Sponsor 4"}
                                    src={flattenToAppURL(parentData.sponsor_4.download)}
                                />}
                            </Container>
                        )}
                        {(parentData.partner_1 || parentData.partner_2 || parentData.partner_3 || parentData.partner_4) && (
                            <Container className="projekt-right-column">
                                <p>Partner</p>
                                {parentData.partner_1 && <Image
                                    alt={"Partner 1"}
                                    title={"Partner 1"}
                                    src={flattenToAppURL(parentData.partner_1.download)}
                                />}
                                {parentData.partner_2 && <Image
                                    alt={"Partner 2"}
                                    title={"Partner 2"}
                                    src={flattenToAppURL(parentData.partner_2.download)}
                                />}
                                {parentData.partner_3 && <Image
                                    alt={"Partner 3"}
                                    title={"Partner 3"}
                                    src={flattenToAppURL(parentData.partner_3.download)}
                                />}
                                {parentData.partner_4 && <Image
                                    alt={"Partner 4"}
                                    title={"Partner 4"}
                                    src={flattenToAppURL(parentData.partner_4.download)}
                                />}
                            </Container>
                        )}
                        {(parentData.informationsflyer) && (
                            <Container>
                                <p>Informationsflyer</p>
                                {parentData.informationsflyer &&
                                    <UniversalLink
                                        href={parentData.informationsflyer.download}
                                        title={parentData.informationsflyer.filename}>
                                        <Image
                                            alt={"Projekt Logo"}
                                            title={"Projekt Logo"}
                                            src={parentData.informationsflyer.download ? flattenToAppURL(parentData.hintergrundbild.download) : ""}
                                        />
                                    </UniversalLink>
                                }
                            </Container>
                        )}
                    </div>
                </div>
            </Container>

        </>
    );
}
export default ProjektPageView;