import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { Menu } from 'semantic-ui-react';
import cx from 'classnames';
import { BodyClass } from '@plone/volto/helpers';
import { CSSTransition } from 'react-transition-group';
import NavItems from '@plone/volto/components/theme/Navigation/NavItems';
import { Api, flattenToAppURL } from '@plone/volto/helpers';
//import ProjektNavItems from './ProjektNavItems';

const messages = defineMessages({
  closeMobileMenu: {
    id: 'Close menu',
    defaultMessage: 'Close menu',
  },
  openMobileMenu: {
    id: 'Open menu',
    defaultMessage: 'Open menu',
  },
});


const apiGetNav = async (pathname, parent) => {
  const api = new Api();
  const url = parent ? flattenToAppURL(parent) : flattenToAppURL(pathname);
  const promise = await api.get(url);

  const items = promise.items.filter((item) => (item['@type'] === 'projektpage' ) );
  const cleanChildItems = items.map((item) => ({ 'url': flattenToAppURL(item['@id']), 'title': item.title }))
  cleanChildItems.unshift({ 'url': flattenToAppURL(parent || pathname), 'title': 'Über uns' });
  return cleanChildItems;
};

const ProjektNavigation = (props) => {
  const intl = useIntl();
  const { pathname, parent, type } = props;
  const [isMobileMenuOpen, setisMobileMenuOpen] = useState(false);
  const [items, setItems] = useState([]); // Use state to manage items
  const lang = useSelector((state) => state.intl.locale);

  useEffect(() => {
    apiGetNav(pathname, parent).then((fetchedItems) => {
      setItems(fetchedItems);
    });

    // avoid Memory Leak
    return () => {
      setItems([]);
      setisMobileMenuOpen(false);
    };
  }, [pathname, parent]);

  const toggleMobileMenu = () => {
    setisMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    if (!isMobileMenuOpen) {
      return;
    }
    setisMobileMenuOpen(false);
  };

  return (
    <nav className="navigation" id="navigation" aria-label="navigation">
      <div className="hamburger-wrapper mobile tablet only">
        <button
          className={cx('hamburger hamburger--spin', {
            'is-active': isMobileMenuOpen,
          })}
          aria-label={
            isMobileMenuOpen
              ? intl.formatMessage(messages.closeMobileMenu, {
                  type: type,
                })
              : intl.formatMessage(messages.openMobileMenu, {
                  type: type,
                })
          }
          title={
            isMobileMenuOpen
              ? intl.formatMessage(messages.closeMobileMenu, {
                  type: type,
                })
              : intl.formatMessage(messages.openMobileMenu, {
                  type: type,
                })
          }
          type="button"
          onClick={toggleMobileMenu}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </div>
      <Menu
        stackable
        pointing
        secondary
        className="computer large screen widescreen only"
        onClick={closeMobileMenu}
      >
        <NavItems items={items} lang={lang} pathname={pathname}/>
      </Menu>
      <CSSTransition
        in={isMobileMenuOpen}
        timeout={500}
        classNames="mobile-menu"
        unmountOnExit
      >
        <div key="mobile-menu-key" className="mobile-menu">
          <BodyClass className="has-mobile-menu-open" />
          <div className="mobile-menu-nav">
            <Menu stackable pointing secondary onClick={closeMobileMenu}>
              <NavItems items={items} lang={lang} pathname={pathname}/>
            </Menu>
          </div>
        </div>
      </CSSTransition>
    </nav>
  );
};

ProjektNavigation.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default ProjektNavigation;
