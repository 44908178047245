/*
This file is autogenerated. Don't change it directly.
Instead, change the "addons" setting in your package.json file.
*/

const projectConfigLoader = require('@root/config');
import plonevoltoSlate from '@plone/volto-slate';
import customSliderNoLink from 'custom-slider-no-link';
import kitconceptvoltoCarouselBlock from '@kitconcept/volto-carousel-block';
import eeacmsvoltoColumnsBlock from '@eeacms/volto-columns-block';
import voltoSubblocks from 'volto-subblocks';
import voltoFormBlock from 'volto-form-block';

const addonsInfo = [{"modulePath":"/usr/src/app/node_modules/@plone/volto/packages/volto-slate/src","packageJson":"/usr/src/app/node_modules/@plone/volto/packages/volto-slate/package.json","version":"17.18.2","isPublishedPackage":false,"isRegisteredAddon":false,"name":"@plone/volto-slate","addons":[]},{"modulePath":"/usr/src/app/src/addons/custom-slider-no-link/src","packageJson":"/usr/src/app/src/addons/custom-slider-no-link/package.json","version":"0.1.0","isPublishedPackage":false,"isRegisteredAddon":true,"name":"custom-slider-no-link","addons":[]},{"name":"@kitconcept/volto-carousel-block","version":"1.1.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/usr/src/app/node_modules/@kitconcept/volto-carousel-block/src","packageJson":"/usr/src/app/node_modules/@kitconcept/volto-carousel-block/package.json","addons":[]},{"name":"@eeacms/volto-columns-block","version":"6.3.5","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/usr/src/app/node_modules/@eeacms/volto-columns-block/src","packageJson":"/usr/src/app/node_modules/@eeacms/volto-columns-block/package.json","addons":[]},{"name":"volto-subblocks","version":"2.0.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/usr/src/app/node_modules/volto-subblocks/src","packageJson":"/usr/src/app/node_modules/volto-subblocks/package.json","addons":[]},{"name":"volto-form-block","version":"3.1.0","isPublishedPackage":true,"isRegisteredAddon":true,"modulePath":"/usr/src/app/node_modules/volto-form-block/src","packageJson":"/usr/src/app/node_modules/volto-form-block/package.json","addons":["volto-subblocks"]}];
export { addonsInfo };

const safeWrapper = (func) => (config) => {
  const res = func(config);
  if (typeof res === 'undefined') {
    throw new Error("Configuration function doesn't return config");
  }
  return res;
}

const projectConfig = (config) => {
  return typeof projectConfigLoader.default === "function" ? projectConfigLoader.default(config) : config;
}

const load = (config) => {
  const addonLoaders = [plonevoltoSlate, customSliderNoLink, kitconceptvoltoCarouselBlock, eeacmsvoltoColumnsBlock, voltoSubblocks, voltoFormBlock];
  if(!addonLoaders.every((el) => typeof el === "function")) {
    throw new TypeError(
      'Each addon has to provide a function applying its configuration to the projects configuration.',
    );
  }
  return projectConfig(addonLoaders.reduce((acc, apply) => safeWrapper(apply)(acc), config));
};
export default load;
