import { Container, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ProjektLogo from './ProjektLogo'
import ProjektNavigation from './ProjektNavigation'
import SearchWidget from '../SearchWidget/SearchWidget';

import {
  LanguageSelector,
  //SearchWidget,
} from '@plone/volto/components';


const ProjektHeader = ({ pathname, logo, parent, farbe }) => {
  const containerStyle = farbe ? { borderBottom: `0.5rem solid ${farbe}` } : null;
  return (
    <div className="projekt-outer-header-wrapper">
      <Segment basic className="projekt-header-wrapper" role="banner">
        <Container style={containerStyle}>
          <div className="header">
            <div className="tools-search-wrapper">
              <LanguageSelector />
              <div className="logo">
                <ProjektLogo path={logo} />
              </div>
            </div>
          </div>
          <div className='nav-search-wrapper'>
            <ProjektNavigation pathname={pathname} parent={parent}/>
            <div className="search">
              <SearchWidget />
            </div>
          </div>
        </Container>
      </Segment>


    </div>
  );
};

export default ProjektHeader;

ProjektHeader.propTypes = {
  token: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  content: PropTypes.objectOf(PropTypes.any),
};

ProjektHeader.defaultProps = {
  token: null,
  content: null,
};
