import React, { useEffect, useState, useCallback } from 'react';
import { RenderBlocks } from "@plone/volto/components"
import { Container, Image } from 'semantic-ui-react'
import ProjektHeader from './ProjektHeader';
import { UniversalLink } from '@plone/volto/components';
import { Api, flattenToAppURL } from '@plone/volto/helpers';
import { CSSTransition } from "react-transition-group";

const ProjektNewsView = (props) => {
    const { content, history, location, token } = props;
    const [parent1Data, setParent1Data] = useState(content);
    const [parent2Data, setParent2Data] = useState(content);
    const parentURL = content.parent['@id']
    const pathname = content['@id'];
    const [loaded, setLoaded] = useState(false);
    const [imageSrc, setImageSrc] = useState('/bilder/dummy-bg.png/@@images/image');

    const testParents = async (path) => {
        const api = new Api();
        const promise = await api.get(flattenToAppURL(path));
        const data = promise
        return data;
    };

    const apiGet = async (pathname) => {
        const parentdata = testParents(pathname)
        return parentdata;
    };

    useEffect(() => {
        if (!loaded)
            // get News-Page 
            apiGet(parentURL).then((fetchedItems1) => {
                // get Projekt-Page with Children
                apiGet(fetchedItems1.parent["@id"]).then((fetchedItems2) => {
                    setImageSrc(fetchedItems2.hintergrundbild ? fetchedItems2.hintergrundbild.download : "")
                    setParent1Data(fetchedItems1);
                    setParent2Data(fetchedItems2);
                    setLoaded(true);
                    return;
                })
            });

        // avoid Memory Leak
        return () => {
            setImageSrc('/bilder/dummy-bg.png/@@images/image');
            setParent1Data(content);
            setParent2Data(content);
            setLoaded(false);
        };
    }, [pathname]);


    return (
        <>
            <ProjektHeader pathname={parent1Data["@id"]} logo={parent2Data.projektlogo ? parent2Data.projektlogo.download : ""} parent={parent2Data["@id"]} farbe={parent2Data.farbe} />
            <Image
                alt={"Projekt Logo"}
                title={"Projekt Logo"}
                src={flattenToAppURL(imageSrc)}
                className={`projekt-head-img ${loaded ? 'fade-in' : ''}`}
            />
            <Container className="view-wrapper newspage">
                <RenderBlocks {...props} content={content || {}} />
            </Container>

        </>
    );
}
export default ProjektNewsView;