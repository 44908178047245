import React from 'react';
import NavItem from './NavItem';

const NavItems = ({ items, lang, pathname }) => {
  return (
    <>
      {items.map((item) => (
        <NavItem item={item} lang={lang} key={"nav" + item.url} pathname={pathname}/>
      ))}
    </>
  );
};

export default NavItems;
