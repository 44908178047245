/**
 * NewsItemView view component.
 * @module components/theme/View/NewsItemView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Image } from 'semantic-ui-react';
import {
  hasBlocksData,
  flattenToAppURL,
  flattenHTMLToAppURL,
} from '@plone/volto/helpers';
import { FormattedDate } from '@plone/volto/components';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';

/**
 * NewsItemView view component class.
 * @function NewsItemView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
function transformDateFormat(inputDate) {
        var parsedDate = new Date(inputDate);
        var day = parsedDate.getDate();
        var month = parsedDate.getMonth() + 1;
        var year = parsedDate.getFullYear();
    
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
    
        var formattedDate = day + '.' + month + '.' + year;
        return formattedDate;
    }
const NewsItemView = ({ content }) => {
  console.log(content)
  return (
    <Container className="view-wrapper newspage">
      {content.review_state === 'published' && content.datum && (
        <p>
         {transformDateFormat(content.datum)}
        </p>
      )}
      {content.title && (
        <h1>
          {content.title}
        </h1>
      )}
      <div className='news_text'>
        <RenderBlocks content={content} location={content.id} className="news_text" />
      </div>
    </Container>
  )
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
NewsItemView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default NewsItemView;
