/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */


// All your imports required for the config here BEFORE this line
import '@plone/volto/config';
import { ProjektTemplate } from './customizations/components/manage/Blocks/Listing';
import { AktuellesTemplate } from './customizations/components/manage/Blocks/Listing';
import { VeranstaltungsTemplate } from './customizations/components/manage/Blocks/Listing';
import { ProjektNewsTemplate } from "./customizations/components/manage/Blocks/Listing"
import { ProjektView } from "./customizations/components/theme/View"
import { ProjektPageView } from "./customizations/components/theme/View"
import { ProjektNewsView } from "./customizations/components/theme/View"
import FunctionWidget from './customizations/components/theme/Widgets/FunctionWidget';

export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
       projekt: ProjektView,
       projektpage: ProjektPageView,
       projektnews: ProjektNewsView,
    },
  };
  config.settings = {
    ...config.settings,
    logo: '/customisations/components/theme/Logo/Logo.png',
    devProxyToApiPath: 'http://localhost:8080/TeleHealth',
    //slate: {
    //  ...config.settings.slate,
    //  toolbarButtons: [...config.settings.slate.toolbarButtons, 'align-center'],
    //},
    
  };
  config.blocks.requiredBlocks = [];
  //{ console.log(config.settings) }
  //config.widgets.widgetMapping = {
  //    ...config.widgets.widgetMapping,
  //    fwidget: FunctionWidget

  //  }
  config.blocks.blocksConfig.listing.variations = [
    ...config.blocks.blocksConfig.listing.variations,
    {
      id: "ProjektTemplate",
      title: "Projekte",
      template: ProjektTemplate,
    },
    {
      id: "AktuellesTemplate",
      title: "Aktuelles",
      template: AktuellesTemplate,
      fullobjects: true,
    },
    {
      id: "VeranstaltungsTemplate",
      title: "Veranstaltung",
      template: VeranstaltungsTemplate,
      fullobjects: true,
    },
    {
      id: "ProjektNewsTemplate",
      title: "ProjektNews",
      template: ProjektNewsTemplate,
      fullobjects: true,
    },
  ];
  return config;
}
