/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment, Image, Grid } from 'semantic-ui-react';

import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
    copyright: {
        id: 'Copyright',
        defaultMessage: 'Copyright',
    },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
    const { settings } = config;
    const lang = useSelector((state) => state.intl.locale);
    return (
        <>
            <div className='background-container'>
                <div className='background-cell' />
            </div>
            <Segment
                className='footer'
                id='footer'
            >
                <Container>
                    <List horizontal inverted>
                        <div role="listitem" className="item border">
                            <UniversalLink href={'/'}>
                                <FormattedMessage id="Startseite" defaultMessage="Startseite" />
                            </UniversalLink>
                        </div>
                        <div role="listitem" className="item border">
                            <UniversalLink href={'/kontakt'}>
                                <FormattedMessage id="Kontakt" defaultMessage="Kontakt" />
                            </UniversalLink>
                        </div>
                        <div role="listitem" className="item border">
                            <UniversalLink href={'/impressum'}>
                                <FormattedMessage id="Impressum" defaultMessage="Impressum" />
                            </UniversalLink>
                        </div>
                        <div role="listitem" className="item border">
                            <UniversalLink href={'/login'}>
                                <FormattedMessage id="Anmelden" defaultMessage="Anmelden" />
                            </UniversalLink>
                        </div>
                        <div role="listitem" className="item border">
                            <UniversalLink href={'/datenschutz'}>
                                <FormattedMessage id="Datenschutzerklärung" defaultMessage="Datenschutzerklärung" />
                            </UniversalLink>
                        </div>
                    </List>
                    <div className="footer-logo">
                        <UniversalLink href={'/'}>
                            <Image
                                alt={"footer logo"}
                                title={"Logo Footer"}
                                src={flattenToAppURL("/bilder/im-footer-portal-logo.png/@@images/image-141-e38191f4733160da049b5431c9339668.png")}
                            />
                        </UniversalLink>
                    </div>
                </Container>

            </Segment>
        </>
    );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
    /**
     * i18n object
     */
};

export default injectIntl(Footer);
