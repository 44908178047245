import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, FormattedDate } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

import { isInternalURL } from '@plone/volto/helpers/Url/Url';

function transformDateFormat(inputDate) {
    var parsedDate = new Date(inputDate);
    var day = parsedDate.getDate();
    var month = parsedDate.getMonth() + 1;
    var year = parsedDate.getFullYear();

    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    var formattedDate = day + '.' + month + '.' + year;
    return formattedDate;
}

const VeranstaltungsTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
    let link = null;
    let href = linkHref?.[0]?.['@id'] || '';

    if (isInternalURL(href)) {
        link = (
            <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
                {linkTitle || href}
            </ConditionalLink>
        );
    } else if (href) {
        link = <a href={href}>{linkTitle || href}</a>;
    }

    const getTimeString = (item) => {
        if (!item || (!item.anfangsdatum && !item.enddatum)) {
            return "";
        }
        const startDate = item.anfangsdatum ? item.anfangsdatum.split("T")[0] : "";
        const endDate = item.enddatum ? item.enddatum.split("T")[0] : "";
        if (!startDate && !endDate) 
            return "";
        if (startDate === endDate) 
            return <FormattedDate date={startDate} />;
        return (
            <p>
                {startDate && transformDateFormat(startDate).slice(0, 3)}
                {endDate &&  " - " + transformDateFormat(endDate)}
            </p>
        );
    };

    return (
        <>
            <div className="items">
                {items.map((item) => (
                    <ConditionalLink item={item} condition={!isEditMode} key={item['@id']} to={item.url}>
                        <div className="">
                            <div className="listing-body">
                                <h4>{item.title ? item.title : item.id}</h4>
                                {getTimeString(item)}
                            </div>
                        </div>
                    </ConditionalLink>
                ))}
            </div>


            {link && <div className="footer">{link}</div>}
        </>
    );
};
VeranstaltungsTemplate.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    linkMore: PropTypes.any,
    isEditMode: PropTypes.bool,
};

export default VeranstaltungsTemplate;
