import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, FormattedDate } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { Image } from 'semantic-ui-react';

const getTeaserText = (item) => {
    if (!item || !item.blocks_layout || !item.blocks) {
        return "";
    }
    for (const key of item.blocks_layout.items) {        
        if (item.blocks[key]["@type"] == "slate") {
            return item.blocks[key]
                .plaintext
                .split(' ')
                .slice(0, 50)
                .join(' ') + " ...";
        }
    }
    return "";
}

const ProjektNewsTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
    let link = null;
    let href = linkHref?.[0]?.['@id'] || '';

    function transformDateFormat(inputDate) {
        var parsedDate = new Date(inputDate);
        var day = parsedDate.getDate();
        var month = parsedDate.getMonth() + 1;
        var year = parsedDate.getFullYear();

        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;

        var formattedDate = day + '.' + month + '.' + year;
        return formattedDate;
    }

    if (isInternalURL(href)) {
        link = (
            <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
                {linkTitle || href}
            </ConditionalLink>
        );
    } else if (href) {
        link = <a href={href}>{linkTitle || href}</a>;
    }


    return (
        <>
            <div className="listing-item projekt-list projekt-heading">
                <div className="listing-body">
                    <h1>News</h1>
                    <p>Aktuelle Informationen</p>
                </div>
            </div>
            <div className="items" >
                {items.map((item) => (
                    <ConditionalLink item={item} condition={!isEditMode} key={item['@id']}>
                        <div className="listing-item projekt-list aktuelles-list slide-down">
                            <div className="listing-body">
                                {item.datum && (<p>{transformDateFormat(item.datum)}</p>)}
                                <h4>{item.title ? item.title : item.id}</h4>
                                {item.image && item.image.download && (<Image
                                    alt={"News Image"}
                                    title={"News Image"}
                                    src={flattenToAppURL(item['@id'] + "/" + item.image.download)}
                                />)}
                                {item.description && (
                                    <p className='blue-color'>{item.description}</p>)}
                                {item.blocks_layout && (
                                    <p>
                                        {getTeaserText(item)}
                                    </p>
                                )}

                                <p className='blue-color'>WEITERLESEN</p>
                            </div>
                        </div>
                    </ConditionalLink>
                ))}
            </div>
            {link && <div className="footer">{link}</div>}
        </>
    );
};
ProjektNewsTemplate.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    linkMore: PropTypes.any,
    isEditMode: PropTypes.bool,
};
export default ProjektNewsTemplate;
