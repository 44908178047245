import React, { useEffect, useState, useCallback } from 'react';
import { RenderBlocks } from "@plone/volto/components"
import { Container, Image } from 'semantic-ui-react'
import ProjektHeader from './ProjektHeader';
import { UniversalLink } from '@plone/volto/components';
import { Api, flattenToAppURL } from '@plone/volto/helpers';

const ProjektView = (props) => {
    const { content, history, location, token } = props;

    return (
        <>
            <ProjektHeader pathname={flattenToAppURL(content['@id'])} logo={content.projektlogo ? content.projektlogo.download : ""} farbe={content.farbe}/>
            <Image
                alt={"Projekt Logo"}
                title={"Projekt Logo"}
                src={content.hintergrundbild ? flattenToAppURL(content.hintergrundbild.download) : ""}
                className="projekt-head-img fade-in"
            />
            <Container>
                <div className="ui grid column-grid projekt">
                    <div className="nine wide computer twelve wide mobile six wide tablet column column-blocks-wrapper ">
                        <Container>
                            <RenderBlocks {...props} content={content || {}} />
                        </Container>
                    </div>
                    <div className="three wide computer twelve wide mobile six wide tablet column column-blocks-wrapper">
                        {(content.sponsor_1 || content.sponsor_2 || content.sponsor_3 || content.sponsor_4) && (
                            <Container className="projekt-right-column">
                                <p>gefördert durch:</p>
                                {content.sponsor_1 && <Image
                                    alt={"Sponsor 1"}
                                    title={"Sponsor 1"}
                                    src={flattenToAppURL(content.sponsor_1.download)}
                                />}
                                {content.sponsor_2 && <Image
                                    alt={"Sponsor 2"}
                                    title={"Sponsor 2"}
                                    src={flattenToAppURL(content.sponsor_2.download)}
                                />}
                                {content.sponsor_3 && <Image
                                    alt={"Sponsor 3"}
                                    title={"Sponsor 3"}
                                    src={flattenToAppURL(content.sponsor_3.download)}
                                />}
                                {content.sponsor_4 && <Image
                                    alt={"Sponsor 4"}
                                    title={"Sponsor 4"}
                                    src={flattenToAppURL(content.sponsor_4.download)}
                                />}
                            </Container>
                        )}
                        {(content.partner_1 || content.partner_2 || content.partner_3 || content.partner_4) && (
                            <Container className="projekt-right-column">
                                <p>Partner</p>
                                {content.partner_1 && <Image
                                    alt={"Partner 1"}
                                    title={"Partner 1"}
                                    src={flattenToAppURL(content.partner_1.download)}
                                />}
                                {content.partner_2 && <Image
                                    alt={"Partner 2"}
                                    title={"Partner 2"}
                                    src={flattenToAppURL(content.partner_2.download)}
                                />}
                                {content.partner_3 && <Image
                                    alt={"Partner 3"}
                                    title={"Partner 3"}
                                    src={flattenToAppURL(content.partner_3.download)}
                                />}
                                {content.partner_4 && <Image
                                    alt={"Partner 4"}
                                    title={"Partner 4"}
                                    src={flattenToAppURL(content.partner_4.download)}
                                />}
                            </Container>
                        )}
                        {(content.informationsflyer) && (
                            <Container>
                                <p>Informationsflyer</p>
                                {content.informationsflyer &&
                                    <UniversalLink
                                        href={content.informationsflyer.download}
                                        title={content.informationsflyer.filename}>
                                        <Image
                                            alt={"Projekt Logo"}
                                            title={"Projekt Logo"}
                                            src={content.informationsflyer.download ? flattenToAppURL(content.hintergrundbild.download) : ""}
                                        />
                                    </UniversalLink>
                                }
                            </Container>
                        )}
                    </div>

                    {content.partner && (
                        <div className="three wide computer twelve wide mobile six wide tablet column column-blocks-wrapper">
                            <Container>
                                Partner
                            </Container>
                        </div>
                    )}
                </div>
            </Container>

        </>
    );
}
export default ProjektView;