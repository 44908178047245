import { Container, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import SearchWidget from '../SearchWidget/SearchWidget';
import {
  Anontools,
  LanguageSelector,
  Logo,
  Navigation,
//  SearchWidget,
} from '@plone/volto/components';

const Header = ({ pathname }) => {
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  //if parent is type Projekt -> Load ProjektHeader from Parent
  return (
    <Segment basic className="header-wrapper" role="banner">
      <Container>
        <div className="header">
          <div className="tools-search-wrapper">
            <LanguageSelector />
            <div className="logo">
              <Logo />
            </div>
          </div>
        </div>
        <div className='nav-search-wrapper'>
          <Navigation pathname={pathname} />
          <div className="search">
            <SearchWidget />
          </div>
        </div>
      </Container>
    </Segment>
  );
};

export default Header;

Header.propTypes = {
  token: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  content: PropTypes.objectOf(PropTypes.any),
};

Header.defaultProps = {
  token: null,
  content: null,
};
